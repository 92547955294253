import {github} from "../Services/Github.js";
import {env} from "../Core/Env.js";
import {hash} from "./sha1.js";
export async function saveChanges(project, params, turtle) {
  const book = project.books.find((book2) => book2.name === params.bookId);
  const bookCid = hash(JSON.stringify(params));
  if (!await github.isLoggedIn()) {
    const redirectUrl = `${env.API}/login`;
    localStorage.redirectUrl = location.pathname;
    location.replace(redirectUrl);
  }
  const commitData = [{
    file: book.settings.files[0].file,
    content: turtle
  }];
  if (project.hasWriteAccess) {
    await github.createCommit(params.ownerId, params.repoId, project.branch, commitData);
  } else {
    const user = await github.getCurrentUser();
    let forkRepo = await github.getForkRepo(params.ownerId, user.login, params.repoId);
    if (!forkRepo) {
      const forkCreateResponse = await github.createForkRepo(params.ownerId, params.repoId);
      forkRepo = forkCreateResponse.data;
      if (!forkRepo)
        throw new Error("Could not fork the repo");
    }
    const branchName = "biblogos-" + hash(turtle);
    await github.createBranchOnFork(user.login, params.ownerId, params.repoId, branchName);
    const {data: commit} = await github.createCommit(user.login, params.repoId, branchName, commitData);
    await github.createMergeRequest(user.login, params.ownerId, params.repoId, branchName, commit.object.sha);
  }
  delete localStorage[bookCid];
  return true;
}
