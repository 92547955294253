import {parseInts} from "../Helpers/parseInts.js";
export class ReferenceProxy {
  #reference;
  constructor(reference) {
    this.#reference = reference;
    const [start, end] = reference.includes(":") ? reference.split(":") : [reference, reference];
    const [startBook, startChapter, startVerse, startWord] = parseInts(start.split("."));
    const startObject = {startBook, startChapter, startVerse, startWord};
    Object.assign(this, startObject);
    const [endBook, endChapter, endVerse, endWord] = parseInts(end.split("."));
    const endObject = {endBook, endChapter, endVerse, endWord};
    Object.assign(this, endObject);
    this.startNumber = this.toNumber(startChapter, startVerse, startWord);
    this.endNumber = this.toNumber(endChapter, endVerse, endWord);
  }
  toNumber(chapter, verse, word) {
    return chapter * 1e4 + verse * 1e3 + word;
  }
  includes(book, chapter, verse, word) {
    if (book !== this.startBook && book !== this.endBook)
      return;
    const number = this.toNumber(chapter, verse, word);
    return number >= this.startNumber && number <= this.endNumber;
  }
  get isShort() {
    return this.startVerse + 4 > this.endVerse;
  }
  get length() {
    return this.endNumber - this.startNumber;
  }
  toString() {
    return this.#reference;
  }
}
