import {html} from "../_snowpack/pkg/ube.js";
import {SiteHeader} from "../Elements/SiteHeader.js";
import {Markdown} from "../Elements/Markdown.js";
import {params} from "../Core/Router.js";
export const Page = {
  name: "home",
  template: () => {
    let title;
    return html`
            <${SiteHeader} .extra=${html`<h2 ref=${(el) => title = el} class="page-title"></h2>`} />
            <${Markdown} onready=${(event) => {
      title.innerHTML = event.detail.attributes.title;
    }} class="route" .src=${params.name} />
        `;
  }
};
