import {HTML, render, html} from "../_snowpack/pkg/ube.js";
import {CreateMarking} from "../Plugins/PopupParts/CreateMarking.js";
import {Search} from "../Plugins/PopupParts/Search.js";
import {Actions} from "../Plugins/PopupParts/Actions.js";
import {DeleteForm} from "../Plugins/PopupParts/DeleteForm.js";
import {ObjectForm} from "../Plugins/PopupParts/ObjectForm.js";
import {Predicates} from "../Plugins/PopupParts/Predicates.js";
import {NewMarking} from "../Plugins/PopupParts/NewMarking.js";
import {SelectSubject} from "../Plugins/PopupParts/SelectSubject.js";
import {Cancel} from "../Plugins/PopupParts/Cancel.js";
const canceler = (event) => event.stopImmediatePropagation();
export class SelectionPopup extends HTML.Span {
  constructor() {
    super();
    this.subject = "";
    this.identifier = "";
    this.predicate = "";
    this.predicateType = "";
    this.object = "";
    this.name = "";
    this.comment = "";
    this.addEventListener("mousedown", canceler);
    this.addEventListener("mouseup", canceler);
    this.addEventListener("click", canceler);
  }
  async upgradedCallback() {
    this.markings = this.selections.flatMap((words) => words.flatMap((word) => word.markings));
    document.body.classList.add("has-selection-popup");
    this.popupParts = [
      new Cancel(this),
      new Predicates(this),
      new Search(this),
      new Actions(this),
      new DeleteForm(this),
      new ObjectForm(this),
      new NewMarking(this),
      new CreateMarking(this),
      new SelectSubject(this)
    ];
    this.classList.add("selection-popup");
    this.draw();
  }
  async draw() {
    const popupPartsThatApply = this.popupParts.filter((popupPart) => popupPart.applies());
    await render(this, html`${popupPartsThatApply.map((popupPart) => popupPart.template())}`);
  }
  remove() {
    document.body.classList.remove("has-selection-popup");
    super.remove();
  }
}
