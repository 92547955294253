import {importGlobalScript} from "../Helpers/importGlobalScript.js";
import {ReferenceProxy} from "./ReferenceProxy.js";
import {StoreProxy} from "./StoreProxy.js";
import {params} from "../Core/Router.js";
import {hash} from "../Helpers/sha1.js";
import {DataFactory, Writer} from "../_snowpack/pkg/n3.js";
const ONTOLOGY = `${location.protocol}//${location.hostname}:${location.port}/ttl/ontology.ttl`;
export class MarkingsStore extends EventTarget {
  constructor(store, bookAbbreviation, prefixes) {
    super();
    this.changes = [];
    this.#prefixes = prefixes;
    const [proxiedStore, storeEventTarget] = StoreProxy(store);
    this.#store = proxiedStore;
    this.#bookAbbreviation = bookAbbreviation;
    this.loadPreviousChanges();
    storeEventTarget.addEventListener("removeQuad", (event) => {
      this.transaction.changes.push(["removed", event.detail, new Date()]);
    });
    storeEventTarget.addEventListener("addQuad", (event) => {
      this.transaction.changes.push(["added", event.detail, new Date()]);
    });
  }
  #store;
  #comunica;
  #bookAbbreviation;
  #prefixes;
  loadPreviousChanges() {
    const id = hash(JSON.stringify(params));
    if (localStorage[id]) {
      const {changes} = JSON.parse(localStorage[id]);
      this.changes = changes;
      for (const transaction of this.changes) {
        for (const [type, quad] of transaction.changes) {
          this.#store[type === "added" ? "addQuad" : "removeQuad"](quad);
        }
      }
    }
  }
  startTransaction(label) {
    this.transaction = {changes: [], label};
  }
  endTransaction() {
    if (this.transaction.changes.length)
      this.changes.push(this.transaction);
    const data = {params, changes: this.changes};
    const dataString = JSON.stringify(data);
    const id = hash(JSON.stringify(params));
    localStorage[id] = dataString;
  }
  async serialize() {
    const writer = new Writer({prefixes: this.#prefixes});
    await this.#store.forEach(async (quad) => {
      if (quad.predicate.value === "https://biblogos.info/ttl/ontology#reference") {
        const reference = quad.object.value;
        const referenceSplit = reference.split(":");
        if (referenceSplit.length === 2 && referenceSplit[0] === referenceSplit[1]) {
          await writer.addQuad(quad.subject, quad.predicate, DataFactory.literal(referenceSplit[0]), quad.graph);
        } else {
          await writer.addQuad(quad.subject, quad.predicate, quad.object, quad.graph);
        }
      } else {
        await writer.addQuad(quad.subject, quad.predicate, quad.object, quad.graph);
      }
    }, null, null, null, null);
    return new Promise((resolve) => {
      writer.end((error, result) => resolve(result));
    });
  }
  get bookAbbreviation() {
    return this.#bookAbbreviation;
  }
  async query(query, sources, debug = false) {
    if (debug)
      console.log(query);
    if (!this.#comunica) {
      const {newEngine} = await importGlobalScript("https://rdf.js.org/comunica-browser/versions/latest/packages/actor-init-sparql/comunica-browser.js", "Comunica");
      this.#comunica = newEngine();
    }
    const response = await this.#comunica.query(query, {sources});
    if (response.type === "update") {
      return response.updateResult;
    }
    if (response.type === "boolean")
      return response.booleanResult;
    const bindings = await response.bindings();
    const result = [];
    for (const binding of bindings) {
      let item = {};
      for (const variable of response.variables) {
        if (response.variables.length === 1)
          item = binding.get(variable)?.value;
        else
          item[variable.substr(1)] = binding.get(variable)?.value;
      }
      result.push(item);
    }
    return result;
  }
  async getMarkings(chapter) {
    const markings = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>

        SELECT ?subject ?reference ?name (COALESCE(?class_predicate, ?property_predicate) as ?predicate) ?comment {
            ?subject biblogos:reference ?reference .
            OPTIONAL { ?subject biblogos:name ?name . }
            OPTIONAL { ?subject biblogos:comment ?comment . }
            OPTIONAL { ?subject a ?class_predicate . }
            OPTIONAL { ?subject biblogos:predicate ?property_predicate . }
            FILTER strstarts(?reference, """${`${this.#bookAbbreviation}.${chapter}"""`})
        }
        `, [this.#store]);
    for (const marking of markings)
      marking.reference = new ReferenceProxy(marking.reference);
    return markings;
  }
  async searchSubject(searchTerms, predicate, linkedSubject = null) {
    if (searchTerms.length === 0 || searchTerms.length === 1 && searchTerms[0].trim() === "")
      return [];
    return this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        SELECT * { 
            ?predicate a <${predicate}> .
            ?predicate biblogos:name ?name .
            ?predicate a/a rdfs:Class .
            OPTIONAL { ?predicate biblogos:comment ?comment . }
            FILTER regex(?name, """${searchTerms.join("|")}""", "i")
        }
        LIMIT 10
    `, [this.#store, ONTOLOGY]);
  }
  async getFactPredicates() {
    return this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        SELECT * { 
            { ?predicate a rdfs:Class } UNION { ?predicate a rdfs:Property } .
            ?predicate rdfs:label ?label .
            ?predicate biblogos:predicateType ?type .
        }
        `, [ONTOLOGY]);
  }
  async insertFact(object) {
    this.startTransaction("Created a new fact");
    await this.deleteFact(object.uri, false);
    const result = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        INSERT DATA { 
            <${object.uri}> a <${object.predicate}> .
            <${object.uri}> biblogos:name """${object.name}""" .

            ${object.references.map((reference) => `
                <${object.uri}> biblogos:reference """${reference}""" .
            `).join("\n")}
            
            ${object.subject ? `
                <${object.uri}> biblogos:subject <${object.subject}> .
            ` : ""}
            ${object.comment ? `
                <${object.uri}> biblogos:comment """${object.comment}""" .
            ` : ""}
        } 
        `, [this.#store]);
    this.endTransaction();
    return result;
  }
  async appendFactReferences(predicate, references) {
    this.startTransaction("Added a reference to an existing fact");
    const result = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        
        INSERT DATA { 
            ${references.map((reference) => `
                <${predicate}> biblogos:reference """${reference}""" .
            `)}
        }
        `, [this.#store]);
    this.endTransaction();
    return result;
  }
  async removeFactReferences(predicate, references) {
    this.startTransaction("Removed a reference to an existing fact");
    const result = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        
        DELETE DATA { 
            ${references.map((reference) => `
                <${predicate}> biblogos:reference """${reference}""" .
            `)}
        }
        `, [this.#store]);
    this.endTransaction();
    return result;
  }
  async deleteFact(factUri, shouldStartTransaction = true) {
    if (shouldStartTransaction)
      this.startTransaction("Removed an existing fact");
    const result = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        DELETE { 
            <${factUri}> a ?type .
            <${factUri}> biblogos:name ?name .
            <${factUri}> biblogos:subject ?subject .
            <${factUri}> biblogos:reference ?reference .
            <${factUri}> biblogos:comment ?comment . 
        } WHERE { 
            <${factUri}> a ?type .
            <${factUri}> biblogos:name ?name .
            <${factUri}> biblogos:reference ?reference .
            OPTIONAL { <${factUri}> biblogos:comment ?comment . }
            OPTIONAL { <${factUri}> biblogos:subject ?subject . }
        }        
        `, [this.#store]);
    if (shouldStartTransaction)
      this.endTransaction();
    return result;
  }
  async getReferencesCountForFact(factUri) {
    const results = await this.query(`
        PREFIX biblogos: <https://biblogos.info/ttl/ontology#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        
        SELECT (count(?reference) as ?count) {
            <${factUri}> biblogos:reference ?reference .
        }
        `, [this.#store]);
    return parseInt(results[0]);
  }
}
