import {html} from "../../_snowpack/pkg/ube.js";
import {PopupPartbase} from "../../Classes/PopupPartBase.js";
import {icon} from "../../Helpers/icon.js";
export class Cancel extends PopupPartbase {
  applies() {
    return true;
  }
  template() {
    return html`
        <button onclick=${() => {
      this.selectionPopup.markingsEditor.clear();
      this.selectionPopup.remove();
    }} class="button cancel-button secondary nowrap">
            ${icon("close")}
        </button>`;
  }
}
