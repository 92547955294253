import {Sha1} from "./sha1.js";
const cache = new Map();
export function stringToColor(text, minLightness = 20, maxLightness = 80, minSaturation = 20, maxSaturation = 90) {
  const cid = JSON.stringify(arguments);
  if (cache.has(cid))
    return cache.get(cid);
  const hash = new Sha1();
  hash.update(text);
  const utf8Encode = new TextEncoder();
  const number = new Uint8Array(utf8Encode.encode(hash.hex())).join("").slice(16);
  const output = "hsl(" + number % 360 + ", " + (number % (maxSaturation - minSaturation) + minSaturation) + "%, " + (number % (maxLightness - minLightness) + minLightness) + "%)";
  cache.set(cid, output);
  return output;
}
