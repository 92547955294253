import {html} from "../../_snowpack/pkg/ube.js";
import {PopupPartbase} from "../../Classes/PopupPartBase.js";
import {icon} from "../../Helpers/icon.js";
import {t} from "../../Helpers/t.js";
import {toCamelCase} from "../../Helpers/toCamelCase.js";
import {lastPart} from "../../Helpers/lastPart.js";
export class NewMarking extends PopupPartbase {
  applies() {
    const base = this.selectionPopup.predicate && this.selectionPopup.form === "search";
    const defaultPredicateType = this.selectionPopup.predicateType !== "predicate";
    const subjectPredicateType = this.selectionPopup.subject && this.selectionPopup.predicateType === "predicate";
    return base && (defaultPredicateType || subjectPredicateType);
  }
  buttonClick() {
    const words = this.selectionPopup.selections.flatMap((words2) => words2.map((word) => word.text).join(" ")).join(" ");
    const subject = this.selectionPopup.subject ? lastPart(this.selectionPopup.subject) : null;
    this.selectionPopup.name = words;
    this.selectionPopup.identifier = toCamelCase((subject ?? "") + " " + words);
    this.selectionPopup.form = "create";
    this.selectionPopup.draw();
  }
  template() {
    return html`
        <button onclick=${this.buttonClick.bind(this)} class="button nowrap">
            ${icon("add")} ${t`New marking`}
        </button>`;
  }
}
