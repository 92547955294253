import {html} from "../../_snowpack/pkg/ube.js";
import {PopupPartbase} from "../../Classes/PopupPartBase.js";
import {t} from "../../Helpers/t.js";
export class SelectSubject extends PopupPartbase {
  applies() {
    return this.selectionPopup.predicate && this.selectionPopup.form === "search" && this.selectionPopup.predicateType === "predicate" && !this.selectionPopup.subject;
  }
  textClick(event) {
    event.stopImmediatePropagation();
    if (!event.target.classList?.contains("word"))
      return;
    const personUri = event.target.getAttribute("person");
    event.target.classList.add("selected");
    this.selectionPopup.subject = personUri;
    this.selectionPopup.form = "search";
    this.selectionPopup.draw();
  }
  template() {
    document.querySelector(".markings-editor").addEventListener("mouseup", this.textClick.bind(this), {
      once: true
    });
    return html`
        <span class="search-result">
            <span class="label">${t`Select the subject`}</span>
        </span>
        `;
  }
}
