import {HTML} from "../_snowpack/pkg/ube.js";
import {marked} from "../_snowpack/pkg/marked.js";
import fm from "../_snowpack/pkg/front-matter.js";
export class Markdown extends HTML.Div {
  async upgradedCallback() {
    this.classList.add("markdown");
    const response = await fetch(`/markdown/${this.src}.md`);
    const text = await response.text();
    const data = fm(text);
    this.innerHTML = marked(data.body);
    this.dispatchEvent(new CustomEvent("ready", {
      detail: data
    }));
  }
}
