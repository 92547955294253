import {HTML, render, html} from "../_snowpack/pkg/ube.js";
import {Menu} from "./Menu.js";
export class SiteHeader extends HTML.Div {
  async upgradedCallback() {
    this.draw();
    this.classList.add("site-header");
  }
  draw() {
    render(this, html`
            <a class="site-logo-link" href="/">
                <img class="favicon" src="/assets/favicon.svg">
                ${location.pathname === "/" ? html`<h1 class="logo-text">BibLogos</h1>` : null}
            </a>

            ${this["extra"]}

            <${Menu} />
        `);
  }
}
