import UniversalRouter from "../_snowpack/pkg/universal-router.js";
import {Home} from "../Routes/Home.js";
import {Editor} from "../Routes/Editor.js";
import {OauthCallback} from "../Routes/OauthCallback.js";
import {Login} from "../Routes/Login.js";
import {NotFound} from "../Routes/NotFound.js";
import {Page} from "../Routes/Page.js";
import {app} from "../app.js";
export let context, params;
const wrapAction = (route) => {
  return (givenContext, givenParams) => {
    context = givenContext;
    params = givenParams;
    return route;
  };
};
const routes = [
  {path: "/", action: wrapAction(Home)},
  {path: "/editor/:ownerId/:repoId/:bookId?/:chapterId?", action: wrapAction(Editor)},
  {path: "/login", action: wrapAction(Login)},
  {path: "/oauth-callback", action: wrapAction(OauthCallback)},
  {path: "/:name", action: wrapAction(Page)},
  {path: "(.*)", action: wrapAction(NotFound)}
];
export const Router = new UniversalRouter(routes);
export const goTo = (path, replace = false) => {
  if (location.pathname !== path)
    history[replace ? "replaceState" : "pushState"]({}, "", path);
  return app.render();
};
