import {html} from "../_snowpack/pkg/ube.js";
import {ProjectsOverview} from "../Elements/ProjectsOverview.js";
import {SiteHeader} from "../Elements/SiteHeader.js";
import {Markdown} from "../Elements/Markdown.js";
export const Home = {
  name: "home",
  template: () => {
    return html`
            <${SiteHeader} />
            <${Markdown} class="route" .src=${"home"} />
            <${ProjectsOverview} />
        `;
  }
};
