var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorate = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import {env} from "../../Core/Env.js";
import {cache} from "../../Decorators/cache.js";
import {TextSourceBase} from "../../Classes/TextSourceBase.js";
import {html} from "../../_snowpack/pkg/ube.js";
export class ApiBible extends TextSourceBase {
  async getChapters() {
    const {bible, book} = this.settings;
    try {
      const response = await fetch(`${env.API}/plugins/api.bible/bibles/${bible}/books/${book}/chapters`);
      const {data} = await response.json();
      return data.filter(({number}) => number !== "intro").map(({number}) => {
        const label = number === "intro" ? "Intro" : number;
        return [parseInt(number), label];
      });
    } catch (exception) {
      throw new Error("Could not fetch chapters from api.bible");
    }
  }
  async getText(chapter) {
    const {bible, book} = this.settings;
    try {
      const response = await fetch(`${env.API}/plugins/api.bible/bibles/${bible}/chapters/${`${book}.${chapter}?content-type=text`}`);
      const {data} = await response.json();
      const regex = /\[(\d+)\]([^[]*)/g;
      const matches = [...data.content.matchAll(regex)];
      if (matches.length === 0)
        return [[1, data.content]];
      return matches.map((match) => {
        const newlinesMatches = /\r|\n/.exec(match[2]);
        const verse = parseInt(match[1]);
        const text = match[2].trim();
        const prefix = (markings) => html`<span class="verse-number word">${verse}${markings} </span>`;
        return [verse, text, prefix, newlinesMatches?.length ?? 0];
      });
    } catch (exception) {
      console.error(exception);
      throw new Error("Could not fetch verses from api.bible");
    }
  }
}
__decorate([
  cache()
], ApiBible.prototype, "getChapters", 1);
__decorate([
  cache()
], ApiBible.prototype, "getText", 1);
