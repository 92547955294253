import {HTML, render, html} from "../_snowpack/pkg/ube.js";
import {github} from "../Services/Github.js";
import {ProjectTeaser} from "./ProjectTeaser.js";
export class ProjectsOverview extends HTML.Div {
  constructor() {
    super(...arguments);
    this.projects = [];
  }
  async upgradedCallback() {
    this.draw();
    this.classList.add("projects-overview");
    this.classList.add("route");
    this.projects = await github.getProjects();
    this.draw();
  }
  draw() {
    render(this, html`
        ${!this.projects ? html`Loading...` : html`
            ${this.projects.map((project) => html`<${ProjectTeaser} .project=${project} />`)}
        `}
        `);
  }
}
