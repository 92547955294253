export function cache() {
  const cache2 = {};
  return function(target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args) {
      const cacheKey = `__cacheKey__${args.toString()}`;
      if (!cache2.hasOwnProperty(cacheKey))
        cache2[cacheKey] = originalMethod.apply(this, args);
      return cache2[cacheKey];
    };
  };
}
