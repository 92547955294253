import {html} from "../../_snowpack/pkg/ube.js";
import {PopupPartbase} from "../../Classes/PopupPartBase.js";
import {icon} from "../../Helpers/icon.js";
import {t} from "../../Helpers/t.js";
export class CreateMarking extends PopupPartbase {
  applies() {
    return !this.selectionPopup.form;
  }
  template() {
    return html`
        <button onclick=${() => {
      this.selectionPopup.form = "search";
      this.selectionPopup.draw();
    }} class="button nowrap">
            ${icon("add")} ${t`Create marking`}
        </button>`;
  }
}
