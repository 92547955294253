import {html, render} from "../_snowpack/pkg/ube.js";
import {MarkingsEditor} from "../Elements/MarkingsEditor.js";
import {SelectionPopup} from "../Elements/SelectionPopup.js";
import {SiteHeader} from "../Elements/SiteHeader.js";
import {BookNavigation} from "../Elements/BookNavigation.js";
export const Editor = {
  name: "editor",
  template: function() {
    let markingsEditor;
    return html`
            <${SiteHeader} .extra=${html`<${BookNavigation} />`} />
            <${MarkingsEditor} class="route" ref=${(element) => markingsEditor = element} onclick=${(event) => {
      const parentPopup = event.target.closest(".selection-popup");
      if (event.target.parentElement === null)
        return;
      if (parentPopup)
        return;
      if (!event.target.classList.contains("word")) {
        markingsEditor.clear();
        document.querySelector(".selection-popup")?.remove();
      }
    }} class="route-editor" onselection=${(event) => {
      const selections = event.detail;
      const firstWord = selections[0][0]?.element;
      document.querySelector(".selection-popup")?.remove();
      if (firstWord) {
        const root = document.createElement("div");
        render(root, html`<${SelectionPopup} .selections=${selections} .markingsStore=${markingsEditor.markingsStore} .markingsEditor=${markingsEditor} />`);
        const popup = root.children[0];
        firstWord.appendChild(popup);
      }
    }} />
        `;
  }
};
