import {html} from "../../_snowpack/pkg/ube.js";
import {PopupPartbase} from "../../Classes/PopupPartBase.js";
import {select} from "../../Helpers/select.js";
import {t} from "../../Helpers/t.js";
export class Predicates extends PopupPartbase {
  applies() {
    return this.selectionPopup.form === "search";
  }
  template() {
    if (!this.factPredicates) {
      this.selectionPopup.markingsStore.getFactPredicates().then((factPredicates) => {
        this.factPredicates = factPredicates;
        this.selectionPopup.draw();
      });
    }
    return this.factPredicates ? select({
      title: t`Relation type`,
      currentValue: this.selectionPopup.predicate ? this.selectionPopup.predicate + "|" + this.selectionPopup.predicateType : "",
      values: [
        ["", "- Select -"],
        ...this.factPredicates.map((factPredicate) => [factPredicate.predicate + "|" + factPredicate.type, factPredicate.label])
      ].filter(([value]) => !this.selectionPopup.markings.find((marking) => marking.predicate === value)),
      onchange: (event) => {
        const [predicate, type] = event.target.value.split("|");
        this.selectionPopup.predicate = predicate;
        this.selectionPopup.predicateType = type;
        this.selectionPopup.draw();
      }
    }) : html`
            <span>${t`Loading...`}</span>
        `;
  }
}
